// import { isInView } from '../../utils/helper';

// const dataAttributeInview = 'data-checkinview';
// const selectorInviewHandler = '[data-checkinview]';
// let elements = null;

// function checkInview() {
//   for (let e = 0; e < elements.length; e += 1) {
//     if (isInView(elements[e], 'partially') && elements[e].hasAttribute(dataAttributeInview)) {
//       elements[e].removeAttribute(dataAttributeInview);
//       elements[e].classList.add('inview');
//     }
//   }
// }

const selectorComponent = '.js-c-cv-badges';
const selectorButtons = '.js-btn';
const selectorBadgeContentWrapper = '.js-badge-content';

const setContentVisibility = (contentTargetClassname) => {
  const contentItems = document.querySelectorAll(selectorBadgeContentWrapper);

  contentItems.forEach((item) => {
    item.classList.remove('active');
  });

  const activeItem = document.querySelector(`.${contentTargetClassname}`);
  activeItem.classList.add('active');
};

const showPanel = (button) => {
  const panel = document.querySelector(selectorComponent);
  panel.classList.add('visible');
  button.classList.add('active');

  button.classList.add('active');
  const contentTargetClassname = button.dataset.target;

  setContentVisibility(contentTargetClassname);
};

const hidePanel = (button) => {
  const panel = document.querySelector(selectorComponent);
  panel.classList.remove('visible');
  button.classList.remove('active');
};

const initSwitchContent = (button) => {
  const buttons = document.querySelectorAll(selectorButtons);

  buttons.forEach((button) => {
    button.classList.remove('active');
  });

  button.classList.add('active');
  const contentTargetClassname = button.dataset.target;

  setContentVisibility(contentTargetClassname);
};

const onButtonClick = (e) => {
  const panel = document.querySelector(selectorComponent);
  const button = e.target;
  e.preventDefault();

  if (button.classList.contains('active')) {
    // button is active and so is the panel visible
    hidePanel(button);
  } else if (panel.classList.contains('visible') === false) {
    // panel is not visible so the button isn't active
    showPanel(button);
  } else {
    // panel is visible but other button is active
    initSwitchContent(button);
  }
};

function initBadges() {
  const buttons = document.querySelectorAll(selectorButtons);
  buttons.forEach((button) => {
    button.addEventListener('click', onButtonClick);
  });
}

function init() {
  if (document.querySelector(selectorComponent) !== null) {
    initBadges();
  }
}

export default {
  init,
};
