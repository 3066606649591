// Import Swiper and modules
import { Swiper, Navigation, Pagination, Autoplay, Lazy } from 'swiper';

// Install modules
Swiper.use([Navigation, Pagination, Autoplay, Lazy]);

const swiperDelay = 3000;
const selectorAdSlider = '.js-ads-slider';
const selectorAdSwiper = `.swiper-container${selectorAdSlider}`;
const selectorSwipePrev = '.js-swiper-button-prev';
const selectorSwipeNext = '.js-swiper-button-next';
const selectorSwiperPagnation = '.swiper-pagination';

// only working if swiper 'loop: false' - otherwise virtual slides are added to the dom by swiper:
function hideSingleSlideElements(elementsToHide) {
  for (let i = 0; i < elementsToHide.length; i += 1) {
    document.querySelector(elementsToHide[i]).style.display = 'none';
  }
}

function initAdSwiper() {
  const adSwiper = new Swiper(selectorAdSwiper, {
    autoplay: {
      delay: swiperDelay,
      stopOnLastSlide: false,
    },
    preloadImages: true,
    updateOnImagesReady: true,
    lazy: {
      loadPrevNext: true,
    },
    loop: true,
    disableOnInteraction: false,
    speed: 1200,
    // Default parameters
    slidesPerView: 1,
    spaceBetween: 10,
    // Responsive breakpoints
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      // when window width is >= 768px
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      // when window width is >= 1024px
      1024: {
        slidesPerView: 4,
        spaceBetween: 40,
      },
    },
    navigation: {
      nextEl: selectorSwipeNext,
      prevEl: selectorSwipePrev,
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
  });

  if (adSwiper.slides.length < 2 && adSwiper.slides.length > 0) {
    // hide pagination and buttons if only 1 slide:
    const elementsToHide = [
      `${selectorAdSwiper} ${selectorSwiperPagnation}`,
      `${selectorAdSwiper} ${selectorSwipePrev}`,
      `${selectorAdSwiper} ${selectorSwipeNext}`,
    ];
    hideSingleSlideElements(elementsToHide);
  }
}

function init() {
  if (document.querySelector(selectorAdSwiper) !== null) {
    initAdSwiper();
  }
}

export default {
  init,
};
