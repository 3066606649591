const dataAttributeWebPCheck = 'data-webp-support-flag';
const selectorWebPCheck = `[${dataAttributeWebPCheck}]`;
const classnameHasSupport = 'webp-supported';
const classnameHasNoSupport = 'webp-not-supported';
let elements = null;
let hasWebPSupport = false;

async function supportsWebp() {
  if (!window.createImageBitmap) return false;
  const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
  const blob = await fetch(webpData).then((r) => r.blob());
  return createImageBitmap(blob).then(
    () => true,
    () => false,
  );
}

const setClassnameFlags = () => {
  elements = document.querySelectorAll(selectorWebPCheck);
  const supportClassName = hasWebPSupport === true ? classnameHasSupport : classnameHasNoSupport;
  for (let e = 0; e < elements.length; e += 1) {
    elements[e].removeAttribute(dataAttributeWebPCheck);
    elements[e].classList.add(supportClassName);
  }
};

async function initWebPCheck() {
  if (await supportsWebp()) {
    hasWebPSupport = true;
    document.body.classList.add(classnameHasSupport);
    setClassnameFlags();
  } else {
    document.body.classList.add(classnameHasNoSupport);
    setClassnameFlags();
  }
}

const init = () => {
  if (document.querySelector(selectorWebPCheck) !== null) {
    initWebPCheck();
  }
};

export default {
  init,
};
