// https://github.com/googlemaps/js-api-loader/
import { Loader } from '@googlemaps/js-api-loader';
import { darkStyle } from './styles-config';

const domain = `${window.location.origin}/`;
const selectorMapContainer = '.map-container';
const selectorDataAtts = '.js-data-atts';
const mapId = 'map';

const companyName = 'Blaq Group AB';
const companyStreetNo = 'Regeringsgatan 111';
const companyZipCity = '111 39 Stockholm';

const consentIdGoogelMaps = 'service-googlemaps';
const classnameHasConsent = 'map-has-consent';
const classnameHasNoConsent = 'map-has-no-consent';

function initMap() {
  // console.log('map initiated');

  const dataAtts = document.querySelector(selectorDataAtts);
  const { apiKey, markerFilename, colorScheme } = dataAtts.dataset;
  const zoom = parseFloat(dataAtts.dataset.zoom);
  const lat = parseFloat(dataAtts.dataset.lat);
  const lng = parseFloat(dataAtts.dataset.lng);

  const image = `${domain}assets/images-static/map-marker/${markerFilename}`;

  let styles = [];

  if (colorScheme === 'greyscale') {
    styles = darkStyle;
  }

  const loader = new Loader({
    apiKey,
    region: 'EN',
    language: 'en',
  });

  const mapOptions = {
    center: {
      lat,
      lng,
    },
    zoom,
    styles,
  };

  loader
    .load()
    .then((google) => {
      // eslint-disable-next-line no-new
      const map = new google.maps.Map(document.getElementById(mapId), mapOptions);
      // Add marker function to make it easy to add multiple locations
      function addMarker(props) {
        // eslint-disable-next-line no-unused-vars
        const mapMarker = new google.maps.Marker({
          position: props.coords,
          map,
          icon: props.image,
          title: props.title,
        });

        // Pop up window
        const mapInfoWindow = new google.maps.InfoWindow({
          content: props.content,
        });

        mapMarker.addListener('click', () => {
          mapInfoWindow.open(map, mapMarker);
        });
      }

      const location = { lat, lng };

      const content = `
        <div class="googlemap-info-window">
          <span class="headline">${companyName}</span><br />
          ${companyStreetNo}<br />
          ${companyZipCity}
        </div>`;

      // Repeat for multiple locations ** Use array and loop though
      addMarker({
        coords: location,
        image,
        title: companyName,
        content: `<h2>${content}<h2/>`,
      });
    })
    .catch((e) => {
      console.log(e);
    });
}

function init() {
  if (document.querySelector(selectorMapContainer) !== null) {
    const MapContainer = document.querySelector(selectorMapContainer);
    const BorlabsGoogleMapsConsentValue =
      window.BorlabsCookie.Consents.hasConsent(consentIdGoogelMaps);
    if (BorlabsGoogleMapsConsentValue !== null) {
      if (BorlabsGoogleMapsConsentValue === true) {
        MapContainer.classList.add(classnameHasConsent);
        initMap();
      } else {
        MapContainer.classList.add(classnameHasNoConsent);
      }
    }
  }
}

export default {
  init,
};
