// const { body } = document;
const selectorNavbar = '.container-navbar';
// const heightNavbar = document.querySelector(selectorNavbar).offsetHeight;
const navbar = document.querySelector(selectorNavbar);
const selectorMenuOverlay = '.js-container-menu';
const menuOverlay = document.querySelector(selectorMenuOverlay);
const classnamePageIsScrolled = 'is-scrolled';
const offsetWhenSticky = 120;
const isScrolledItems = [navbar, menuOverlay];

let lastKnownScrollYPosition = 0;
let ticking = false;

function handleStickyState(currentYOffset) {
  if (currentYOffset <= offsetWhenSticky) {
    // browser is scrolled to page-top
    // console.log(`current offset ${currentYOffset} is smaller than: ${offsetWhenSticky}`);
    isScrolledItems.forEach((item) => {
      item.classList.remove(classnamePageIsScrolled);
    });
  } else {
    // console.log(`current offset ${currentYOffset} is bigger than: ${offsetWhenSticky}`);
    isScrolledItems.forEach((item) => {
      item.classList.add(classnamePageIsScrolled);
    });
  }
  // console.log(`navbar height: ${heightNavbar}`);
}

const initEventlistener = () => {
  document.addEventListener('scroll', () => {
    lastKnownScrollYPosition = window.scrollY;

    if (!ticking) {
      window.requestAnimationFrame(() => {
        handleStickyState(lastKnownScrollYPosition);
        ticking = false;
      });

      ticking = true;
    }
  });
};

function init() {
  if (document.querySelector(selectorNavbar) !== null) {
    initEventlistener();
  }
}

export default {
  init,
};
