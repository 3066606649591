import { scrollToElement } from '../../utils/helper';

const selectorBurgerButton = '.js-hamburger-button';
const selectorContainerNavbar = '.container-navbar';
const selectorMenuMain = '.js-container-menu';
const selectorIconScrollDown = '.js-icon-scroll-down';
const iconScrollDown = document.querySelector(selectorIconScrollDown);
const selectorPageTop = '.js-page-top';
const burgerButton = document.querySelector(selectorBurgerButton);
const containerNavbar = document.querySelector(selectorContainerNavbar);
const menuMain = document.querySelector(selectorMenuMain);

const initEventlistener = () => {
  burgerButton.addEventListener('click', () => {
    console.log('burger button clicked');
    burgerButton.classList.toggle('is-active');
    containerNavbar.classList.toggle('menu-is-active');
    menuMain.classList.toggle('is-active');
  });
};

const initScrollDownEventlistener = () => {
  iconScrollDown.addEventListener('click', () => {
    const offsetHeader = 0;
    // if (deviceMatchesMediaQuery('only screen and (min-width: 760px)')) {
    //   offsetHeader = -131;
    // }
    scrollToElement(selectorPageTop, 1000, offsetHeader);
  });
};

function init() {
  if (document.querySelector(selectorBurgerButton) !== null) {
    initEventlistener();
  }
  if (document.querySelector(selectorIconScrollDown) !== null) {
    initScrollDownEventlistener();
  }
}

export default {
  init,
};
