import { isInView } from '../../utils/helper';

const dataAttributeInview = 'data-checkinview';
const selectorInviewHandler = '[data-checkinview]';
let elements = null;

function checkInview() {
  for (let e = 0; e < elements.length; e += 1) {
    if (isInView(elements[e], 'partially') && elements[e].hasAttribute(dataAttributeInview)) {
      elements[e].removeAttribute(dataAttributeInview);
      elements[e].classList.add('inview');
    }
  }
}

function initInviewCheck() {
  elements = document.querySelectorAll(selectorInviewHandler);
  checkInview();
  document.addEventListener('scroll', checkInview, false);
}

function init() {
  if (document.querySelector(selectorInviewHandler) !== null) {
    initInviewCheck();
  }
}

export default {
  init,
};
