import domready from 'domready';
import webpDetect from './utils/webp-detect';
import swiper from './components/swiper/index';
import navbar from './components/navbar/index';
import { initLazy } from './components/lazy-load/index';
import burgerButton from './components/menu/index';
import foundation from './components/foundation/index';
import googleMap from './components/map/index';
import inViewAnimation from './components/effects/inview-animation';
import cvBadges from './components/cv-badges/index';

function startApp() {
  webpDetect.init();
  initLazy();
  swiper.init();
  navbar.init();
  burgerButton.init();
  googleMap.init();
  foundation.init();
  inViewAnimation.init();
  cvBadges.init();
}

domready(() => {
  startApp();
});

// https://github.com/geosigno/simpleParallax.js
